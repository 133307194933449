import {configureStore} from "@reduxjs/toolkit";
//import mainClassReducer from '../Components/Layout/mainBodySlice';
//import isSocket from '../Components/Layout/demoSlice';
import authClassReducer from '../Features/Auth/AuthSlice';

const rottReducer = {
   // mainClass: mainClassReducer,
    auth: authClassReducer,
 //   testSocket: isSocket,
}

const store = configureStore({
    reducer: rottReducer
});

export default store;
