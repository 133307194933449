export default {
    USER_TOKEN: 'X-Auth-Token',
    USER: 'User',
    THEME_COLOR:'Theme-Color',
    LIMIT: 20,
    debounceTimeout: 1000,
    imageExtension: ['image/bmp', 'image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/webp'],
    videoExtension:  ['video/mp4'],

}
