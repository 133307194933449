
import React from "react";

import './loading.css'

export default class Loading extends React.Component {

    constructor(props) {
        super(props);
        this.minShowingTime = 200;
        this.state = {
            isLoading : false,
            setIsLoading : (isLoading) => {
                if (isLoading != this.state.isLoading) {
                    let curTimeLong = new Date().getTime();
                    if (isLoading) {
                        this.startTime = curTimeLong;
                        this.setState({
                            isLoading
                        });
                    } else {
                        let hasShowingTimeLong = curTimeLong - this.startTime;
                        if (hasShowingTimeLong < this.minShowingTime) {
                            setTimeout(() => {
                                this.setState({
                                    isLoading
                                });
                            }, this.minShowingTime - hasShowingTimeLong);

                        } else {
                            this.setState({
                                isLoading
                            });
                        }
                    }

                }
            },
        };
    }

    showLoading = () => {
        this.state.setIsLoading(true);
    };

    dismissLoading = () => {
        this.state.setIsLoading(false);

    };

    render() {

        if (!this.state.isLoading) {
            return null;
        }
        return (<div className="my-loading" id="fromloading"></div>)
    }
}

