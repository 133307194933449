import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux"
import store from "./app/store";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Loading from './Components/Loading/Index';

ReactDOM.render(
   // <App />,
    //<React.StrictMode>
        <Provider store={store}>
                 <App/>
                <Loading ref={(ref) => {
                        global.loadingComponentRef = ref
                }}/>
        </Provider>,
   // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
