import React, {Suspense} from 'react';
import {BrowserRouter, Route, Redirect, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import NotFound from "./Components/NotFound";
const login = React.lazy(() => import('./Features/Auth/Login'));
const privateRoute = React.lazy(() => import('./PrivateRoute'));

function App() {

    const authen = useSelector(state => state.auth);
    const isToken = authen.token;
    return (

        <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
                <Route path="/login" component={login}/>
                {isToken ?
                    (<Route exact path="/*" component={privateRoute}/>) :
                    (<Redirect to="/login"/>)
                }
            </BrowserRouter>
        </Suspense>

    );
}

export default App;


