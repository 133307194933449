import React from 'react';

NotFound.propTypes = {};

function NotFound() {
  return (
      <div className="content-wrapper">
          <section className="content">
              <div className="col-md-12">
                  Oopss ... Not found
              </div>
          </section>
      </div>

  );
}

export default NotFound;
