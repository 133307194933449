import {createSlice} from "@reduxjs/toolkit";
import APP_CONSTANTS from '../../Config/AppConstants'

const authSlice = createSlice({
    name: "authSlice",
    initialState: {
        token: localStorage.getItem(APP_CONSTANTS.USER_TOKEN)
    },
    reducers: {

        authenUser: (state, action) => {
            const dataUser = action.payload;
            let user = '';

            if (dataUser.user != null) {
                user = JSON.stringify(dataUser.user);
            }

            localStorage.setItem(APP_CONSTANTS.USER_TOKEN, dataUser.token);
            localStorage.setItem(APP_CONSTANTS.USER, user);
            state.token = dataUser.token;
        }
    }
});

const {reducer, actions} = authSlice;
export const {authenUser} = actions;
export default reducer;
